/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	overflow: hidden;
}

body {
	/* overflow: auto;
	height: 100vh; */
}

.content-container {
	height: calc(100vh - theme('spacing.24'));
}

.drawer-width {
	width: calc(100vw - theme('spacing.10'));
}
.drawer-position {
	right: calc(theme('spacing.10') - 100vw);
}

.drawer-position-left {
	left: calc(theme('spacing.10') - 100vw);
}

input:checked ~ .dot {
	transform: translateX(130%);
	background-color: #fffff;
}

@layer base {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a,
	span,
	label {
		@apply font-poppins;
		@apply leading-5;
		@apply text-sm;
		@apply 2xl:text-base;
		@apply 2xl:leading-6;
	}
}

@font-face {
	font-family: 'Uthmanic';
	src: local('Uthmanic'), url(./assets/fonts/Uthmanic.otf) format('opentype');
}
